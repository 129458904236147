/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import React, { useEffect, useState } from 'react';
import AppSyncBackend from '../../common/backend/AppSyncBackend';
import FhirBackend from '../../common/backend/FhirBackend';

import {
  Box,
  Button,
  FormField,
  Container,
  SpaceBetween,
  Header,
  Flashbar,
  Spinner
} from '@cloudscape-design/components';
import { validateNHI } from '../../common/utils/NHIValidator';
import PathwayAutosuggest from '../common/PathwayAutosuggest';
import { ExploreResults } from './ExploreResults';
import { CustomBreadCrumb } from '../common/CustomBreadCrumb';

import { appSyncDefaultApiResponse } from '../../common/backend/ApiResponse';
import { ApolloQueryResult } from '@apollo/client';

export const Explore: React.FC<{
  appSyncBackend: AppSyncBackend;
  fhirBackend: FhirBackend;
}> = ({ appSyncBackend, fhirBackend }) => {
  // SearchResults page uses AWS Tables. AWS Tables requires a static number of table items. Therefore let's pull
  // the maximum number of results that are supported
  const [nhi, setNhi] = React.useState('');
  const [nhiError, setNhiError] = React.useState('');
  const [flashbarItems, setFlashbarItems] = React.useState<any[]>([]);
  const [isSearching, setIsSearching] = React.useState(false);

  const [apiResponse, setApiResponse] = useState<ApolloQueryResult<any>>(appSyncDefaultApiResponse);

  async function explorePathway(): Promise<any> {
    setIsSearching(true);
    const response = await appSyncBackend.explore(nhi);
    setIsSearching(false);
    return response;
  }

  useEffect(() => {
    setNhiError(validateNHI(nhi));
  }, [nhi]);

  function pathwayForm() {
    return (
      <SpaceBetween direction="vertical" size="m">
        <CustomBreadCrumb items={[{ text: 'Search Patient', href: '/explore' }]} />
        <Container header={<Header variant="h2">Search Patient</Header>}>
          <Box>
            <FormField errorText={nhi === '' ? '' : nhiError} label="Patient Identifier">
              <SpaceBetween direction="horizontal" size="xs">
                <PathwayAutosuggest setNhi={setNhi} nhi={nhi} nhiError={nhiError} />
                <Button
                  loading={isSearching}
                  variant="primary"
                  disabled={nhiError !== ''}
                  onClick={async () => {
                    const result = await explorePathway();
                    setApiResponse(result);
                    if (!result.error) {
                      setFlashbarItems([]);
                    } else {
                      setFlashbarItems([
                        {
                          header: 'Failed to identify Patient',
                          type: 'error',
                          content: result.error.message ?? 'An unknown error occured',
                          dismissible: true,
                          onDismiss: () => setFlashbarItems([])
                        }
                      ]);
                    }
                  }}
                >
                  Search
                </Button>
              </SpaceBetween>
            </FormField>
          </Box>
        </Container>
      </SpaceBetween>
    );
  }

  return (
    <>
      {flashbarItems.length > 0 && (
        <>
          <Flashbar items={flashbarItems} />
          <br />
        </>
      )}

      {apiResponse.data ? (
        <Box>
          {isSearching ? (
            <Spinner size="large" />
          ) : apiResponse.data?.getPathwayByNHI === null || apiResponse.error ? (
            pathwayForm()
          ) : (
            <ExploreResults apiResponse={apiResponse} setApiResponse={setApiResponse} fhirBackend={fhirBackend} />
          )}
        </Box>
      ) : (
        pathwayForm()
      )}
    </>
  );
};
