/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import { Box, BreadcrumbGroup, Header } from '@cloudscape-design/components';
import React from 'react';
import { useHistory } from 'react-router-dom';
export const CustomBreadCrumb: React.FC<{
  items: { text: string; href: string }[];
  header?: JSX.Element;
}> = ({ items, header }) => {
  const history = useHistory();
  return (
    <Box>
      <BreadcrumbGroup
        items={[{ text: 'PCDS', href: '/' }].concat(items)}
        ariaLabel="Breadcrumbs"
        onClick={(e) => {
          e.preventDefault();
          history.push(e.detail.href);
        }}
      />
      {header}
    </Box>
  );
};
