/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  Button,
  Container,
  Header,
  Table,
  Box,
  SpaceBetween,
  ColumnLayout,
  Tabs,
  Link,
  TextContent,
  StatusIndicator,
  Modal
} from '@cloudscape-design/components';
import { appSyncDefaultApiResponse } from '../../common/backend/ApiResponse';
import ApiResponse, { defaultApiResponse } from '../../common/backend/ApiResponse';
import FhirBackend from '../../common/backend/FhirBackend';
import './ExploreResults.scss';
import { ApolloQueryResult } from '@apollo/client';
import {
  PathwayPatient,
  PathwayDiagnosis,
  PathwayDiagnosisInfo,
  PathwayRegimen,
  PathwayMedicationAdministration,
  PathwayCycle,
  PathwayMedicationRequest,
  PathwayDiagnosisStage
} from './Types';
import { Results } from '../common/Results';
import { CustomBreadCrumb } from '../common/CustomBreadCrumb';
import ValueWithLabel from '../ValueWithLabel';
import Status from '../Status';
import { OnThisPageNavigation } from './OnThisPageNavigation';

export const ExploreResults: React.FC<{
  apiResponse: ApolloQueryResult<any>;
  setApiResponse: Dispatch<SetStateAction<ApolloQueryResult<any>>>;
  fhirBackend: FhirBackend;
}> = ({ apiResponse, setApiResponse, fhirBackend }) => {
  const [fhirApiResponse, setFhirApiResponse] = useState<ApiResponse>(defaultApiResponse);
  const [isReading, setIsReading] = React.useState(false);
  const [viewSelection, setViewSelection] = React.useState({ label: 'Classic View', value: 'classic' });
  const [lastTreatmentVisible, setLastTreatmentVisible] = React.useState(false);
  const [currentCycleVisible, setCurrentCycleVisible] = React.useState(false);
  const [futureCycleVisible, setFutureCycleVisible] = React.useState(false);
  const [regimenActiveTabId, setRegimenActiveTabId] = React.useState('0');
  const [diagnosisActiveTabId, setDiagnosisActiveTabId] = React.useState('0');

  async function readResource(resourceType: string, resourceId: string): Promise<any> {
    setIsReading(true);
    const response = await fhirBackend.read(resourceType, resourceId);
    setIsReading(false);
    return response;
  }

  const {
    data: {
      getPathwayByNHI: { patient, diagnoses, linkedNhis }
    }
  }: {
    data: {
      getPathwayByNHI: {
        patient: PathwayPatient;
        diagnoses: PathwayDiagnosisInfo[];
        linkedNhis: string[];
      };
    };
  } = apiResponse;

  const PatientInfo = ({ patient, linkedNhis }: { patient: PathwayPatient; linkedNhis: string[] }) => (
    <Container
      header={
        <Box>
          <Link
            fontSize="heading-l"
            onFollow={async (e) => {
              const result = await readResource('Patient', patient.id);
              setFhirApiResponse(result);
              let fhir = document.getElementById('fhir');
              e.preventDefault();
              setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
            }}
          >
            Patient Information
          </Link>
          {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
        </Box>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        <ColumnLayout columns={4} variant="text-grid">
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Given Name">{patient.name.given}</ValueWithLabel>
            <ValueWithLabel label="Family Name">{patient.name.family}</ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Age">{patient.age}</ValueWithLabel>
            <ValueWithLabel label="Birth Date">{patient.birthDate}</ValueWithLabel>
          </SpaceBetween>

          <ValueWithLabel label="Gender">{patient.gender}</ValueWithLabel>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="NHI">{patient.nhi}</ValueWithLabel>
            {linkedNhis.length ? (
              <ValueWithLabel label="Linked NHI Numbers">{linkedNhis.join(', ')}</ValueWithLabel>
            ) : null}
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );

  const SimplePatientInfo = ({ patient, linkedNhis }: { patient: PathwayPatient; linkedNhis: string[] }) => (
    <Container header={<Header variant="h3">Patient Information</Header>}>
      <SpaceBetween size="xs" direction="vertical">
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <p>
                Name: {patient.name.given} <strong>{patient.name.family}</strong> (<strong>{patient.nhi}</strong>
                {linkedNhis.filter((nhi) => nhi !== patient.nhi).map((nhi) => `, ${nhi}`)})
              </p>
            </TextContent>
            <TextContent>
              <p>
                DOB: <strong>{patient.birthDate}</strong>
              </p>
            </TextContent>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <p>
                Gender: <strong>{patient.gender}</strong>
              </p>
            </TextContent>
            <TextContent>
              <p>
                Age: <strong>{patient.age}</strong>
              </p>
            </TextContent>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );

  const DiagnosisDetails = ({ diagnosis }: { diagnosis: PathwayDiagnosis }) => (
    <Container header={<Header variant="h2">Diagnosis Details</Header>}>
      <SpaceBetween size="m" direction="vertical">
        <ColumnLayout columns={4} variant="text-grid">
          <SpaceBetween direction="vertical" size="m">
            <Box>
              <Link
                fontSize="heading-xs"
                onFollow={async (e) => {
                  const result = await readResource('Condition', diagnosis.id);
                  setFhirApiResponse(result);
                  let fhir = document.getElementById('fhir');
                  e.preventDefault();
                  setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                }}
              >
                <ValueWithLabel label="Diagnosis">{diagnosis.diagnosis}</ValueWithLabel>
              </Link>
              {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
            </Box>
            <ValueWithLabel label="Date of Diagnosis">{diagnosis.dateOfDiagnosis.split(' ')[0]}</ValueWithLabel>
          </SpaceBetween>

          <Box>
            <Link
              fontSize="heading-xs"
              onFollow={async (e) => {
                const result = await readResource('Observation', diagnosis.evidenceId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              <ValueWithLabel label="Evidence">{diagnosis.evidence}</ValueWithLabel>
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Histology Date">{diagnosis.histologyDate.split(' ')[0]}</ValueWithLabel>
            <ValueWithLabel label="Histology">{diagnosis.histology}</ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Laterality">{diagnosis.laterality}</ValueWithLabel>
            <ValueWithLabel label="Body Site">{diagnosis.bodySite}</ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );

  const SimpleDiagnosisDetails = ({ diagnosis }: { diagnosis: PathwayDiagnosis }) => (
    <Container
      header={
        <Header variant="h3">
          {diagnosis.diagnosis}
          {diagnosis.dateOfDiagnosis !== 'N/A' ? ` (diagnosed ${diagnosis.dateOfDiagnosis.split(' ')[0]}):` : ':'}
        </Header>
      }
    >
      <SpaceBetween size="xs" direction="vertical">
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <p>
                Laterality: <strong>{diagnosis.laterality}</strong>
              </p>
            </TextContent>
            <TextContent>
              <p>
                Body Site: <strong>{diagnosis.bodySite}</strong>
              </p>
            </TextContent>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="xs">
            <ValueWithLabel label="Histology">
              {diagnosis.histology}{' '}
              {diagnosis.histologyDate !== 'N/A' ? <strong>({diagnosis.histologyDate.split(' ')[0]})</strong> : null}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );

  const StageDetails = ({ diagnosis }: { diagnosis: PathwayDiagnosis }) => {
    const stagesSummaryColumns = [
      {
        header: 'Stage Grouping',
        cell: (item: PathwayDiagnosisStage) => item.stageGrouping,
        id: 'stageGrouping'
      },
      {
        header: 'Stage',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.stageId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.stage}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'stage'
      },
      {
        header: 'Stage Date',
        cell: (item: PathwayDiagnosisStage) => item.stageDate.split(' ')[0],
        id: 'stageDate'
      },
      {
        header: 'T Score',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.tId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.t} {item.tDate.split(' ')[0]}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'tScore'
      },
      {
        header: 'N Score',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.nId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.n} {item.nDate.split(' ')[0]}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'nScore'
      },
      {
        header: 'M Score',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.mId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.m} {item.mDate.split(' ')[0]}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'mScore'
      }
    ];

    return (
      <Container header={<Header variant="h2">Stage Summary</Header>}>
        <Table
          variant="borderless"
          wrapLines={true}
          empty="No stage assessments have been done"
          items={diagnosis.stages}
          columnDefinitions={stagesSummaryColumns}
        />
      </Container>
    );
  };

  const SimpleStageDetails = ({ diagnosis }: { diagnosis: PathwayDiagnosis }) => {
    const stagesSummaryColumns = [
      {
        header: 'Staging',
        cell: (item: PathwayDiagnosisStage) => <strong>{item.stageGrouping}</strong>,
        id: 'stageGrouping'
      },
      {
        header: 'Stage',
        cell: (item: PathwayDiagnosisStage) =>
          item.stage !== 'N/A' ? (
            <strong>{item.stage.toLocaleLowerCase().replace('stage', '').trim()}</strong>
          ) : (
            <strong>{item.stage}</strong>
          ),
        id: 'stage'
      },
      {
        header: 'Date',
        cell: (item: PathwayDiagnosisStage) => <strong>{item.stageDate.split(' ')[0]}</strong>,
        id: 'stageDate'
      },
      {
        header: 'T',
        cell: (item: PathwayDiagnosisStage) => <strong>{item.t.replace('pT', '').replace('cT', '')}</strong>,
        id: 'tScore'
      },
      {
        header: 'N',
        cell: (item: PathwayDiagnosisStage) => <strong>{item.n.replace('pN', '').replace('cN', '')}</strong>,
        id: 'nScore'
      },
      {
        header: 'M',
        cell: (item: PathwayDiagnosisStage) => <strong>{item.m.replace('pM', '').replace('cM', '')}</strong>,
        id: 'mScore'
      }
    ];

    return (
      <Container header={<Header variant="h3">Stage Summary</Header>}>
        <Table
          variant="borderless"
          wrapLines={true}
          empty="No stage assessments have been done"
          items={diagnosis.stages}
          columnDefinitions={stagesSummaryColumns}
        />
      </Container>
    );
  };

  const CycleInfo = ({ cycle }: { cycle: PathwayCycle }) => {
    const medicationAdministrationSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationAdministration) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('MedicationAdministration', item.id);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.name}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationAdministration) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationAdministration) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationAdministration) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      },
      {
        header: 'Date',
        cell: (item: PathwayMedicationAdministration) => item.start?.split(' ')[0],
        id: 'date'
      },
      {
        header: 'Start',
        cell: (item: PathwayMedicationAdministration) => (item.start?.split(' ')[1] ? item.start.split(' ')[1] : 'N/A'),
        id: 'start'
      },
      {
        header: 'End',
        cell: (item: PathwayMedicationAdministration) => (item.end?.split(' ')[1] ? item.end.split(' ')[1] : 'N/A'),
        id: 'end'
      }
    ];

    const medicationRequestSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationRequest) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('MedicationRequest', item.id);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.name}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationRequest) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationRequest) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationRequest) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      }
    ];

    return (
      <Box padding={{ left: 'l', right: 'l' }}>
        <SpaceBetween direction="vertical" size="l">
          <Box>
            <ColumnLayout columns={1}>
              <SpaceBetween direction="vertical" size="m">
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween direction="vertical" size="m">
                    <Box>
                      <Link
                        fontSize="heading-xs"
                        onFollow={async (e) => {
                          const result = await readResource('CarePlan', cycle.id);
                          setFhirApiResponse(result);
                          let fhir = document.getElementById('fhir');
                          e.preventDefault();
                          setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                        }}
                      >
                        <ValueWithLabel label="Cycle Number">{cycle.number}</ValueWithLabel>
                      </Link>
                      {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
                    </Box>
                    <ValueWithLabel label="Cycle Status">
                      <Status>{cycle.status.toLocaleLowerCase()}</Status>
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween direction="vertical" size="m">
                    <ValueWithLabel label="Cycle Start Date">{cycle.start.split(' ')[0]}</ValueWithLabel>
                    <ValueWithLabel label="Cycle End Date">{cycle.end.split(' ')[0]}</ValueWithLabel>
                  </SpaceBetween>
                  <ValueWithLabel label="Cycle Duration">{cycle.duration}</ValueWithLabel>
                </ColumnLayout>
              </SpaceBetween>
              <br />
              <Table
                header={<Header variant="h3">Medication Administration</Header>}
                variant="borderless"
                wrapLines={true}
                empty="No medication administrations"
                items={cycle.medicationAdministrations}
                columnDefinitions={medicationAdministrationSummaryColumns}
              />
              <Table
                header={<Header variant="h3">Medication Requests</Header>}
                variant="borderless"
                wrapLines={true}
                empty="No mediciation requests"
                items={cycle.medicationRequests}
                columnDefinitions={medicationRequestSummaryColumns}
              />
            </ColumnLayout>
          </Box>
        </SpaceBetween>
      </Box>
    );
  };
  const SimpleCycleInfo = ({ cycle }: { cycle: PathwayCycle }) => {
    const medicationAdministrationSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationAdministration) => item.name,
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationAdministration) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationAdministration) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationAdministration) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      },
      {
        header: 'Date',
        cell: (item: PathwayMedicationAdministration) => item.start?.split(' ')[0],
        id: 'date'
      },
      {
        header: 'Start',
        cell: (item: PathwayMedicationAdministration) => (item.start?.split(' ')[1] ? item.start.split(' ')[1] : 'N/A'),
        id: 'start'
      },
      {
        header: 'End',
        cell: (item: PathwayMedicationAdministration) => (item.end?.split(' ')[1] ? item.end.split(' ')[1] : 'N/A'),
        id: 'end'
      }
    ];

    const medicationRequestSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationRequest) => item.name,
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationRequest) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationRequest) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationRequest) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      }
    ];

    return (
      <Box padding={{ left: 'l', right: 'l' }}>
        <SpaceBetween direction="vertical" size="l">
          <Box>
            <ColumnLayout columns={1}>
              <SpaceBetween direction="vertical" size="m">
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween direction="vertical" size="m">
                    <ValueWithLabel label="Cycle Number">{cycle.number}</ValueWithLabel>
                    <ValueWithLabel label="Cycle Status">
                      <Status>{cycle.status.toLocaleLowerCase()}</Status>
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween direction="vertical" size="m">
                    <ValueWithLabel label="Cycle Start Date">{cycle.start.split(' ')[0]}</ValueWithLabel>
                    <ValueWithLabel label="Cycle End Date">{cycle.end.split(' ')[0]}</ValueWithLabel>
                  </SpaceBetween>
                  <ValueWithLabel label="Cycle Duration">{cycle.duration}</ValueWithLabel>
                </ColumnLayout>
              </SpaceBetween>
              <br />
              <Table
                header={<Header variant="h3">Medication Administration</Header>}
                variant="borderless"
                wrapLines={true}
                empty="No medication administrations"
                items={cycle.medicationAdministrations}
                columnDefinitions={medicationAdministrationSummaryColumns}
              />
              <Table
                header={<Header variant="h3">Medication Requests</Header>}
                variant="borderless"
                wrapLines={true}
                empty="No mediciation requests"
                items={cycle.medicationRequests}
                columnDefinitions={medicationRequestSummaryColumns}
              />
            </ColumnLayout>
          </Box>
        </SpaceBetween>
      </Box>
    );
  };

  const RegimenDetails = ({ regimen }: { regimen: PathwayRegimen }) => {
    const medicationAdministrationSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationAdministration) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('MedicationAdministration', item.id);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.name}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationAdministration) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationAdministration) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationAdministration) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      },
      {
        header: 'Date',
        cell: (item: PathwayMedicationAdministration) => item.start?.split(' ')[0],
        id: 'date'
      },
      {
        header: 'Start',
        cell: (item: PathwayMedicationAdministration) => (item.start?.split(' ')[1] ? item.start.split(' ')[1] : 'N/A'),
        id: 'start'
      },
      {
        header: 'End',
        cell: (item: PathwayMedicationAdministration) => (item.end?.split(' ')[1] ? item.end.split(' ')[1] : 'N/A'),
        id: 'end'
      }
    ];

    return (
      <>
        <Box padding={{ bottom: 'm', left: 'l', right: 'l' }}>
          <SpaceBetween size="xxl" direction="vertical">
            <SpaceBetween direction="vertical" size="m">
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween direction="vertical" size="m">
                  <Box>
                    <Link
                      fontSize="heading-xs"
                      onFollow={async (e) => {
                        const result = await readResource('CarePlan', regimen.id);
                        setFhirApiResponse(result);
                        let fhir = document.getElementById('fhir');
                        e.preventDefault();
                        setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                      }}
                    >
                      <ValueWithLabel label="Intent of Treatment">{regimen.intent}</ValueWithLabel>
                    </Link>
                    {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
                  </Box>
                  <ValueWithLabel label="Status of Treatment">
                    <Status>{regimen.status.toLocaleLowerCase()}</Status>
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="m">
                  <Box>
                    <Link
                      fontSize="heading-xs"
                      onFollow={async (e) => {
                        const result = await readResource('Observation', regimen.ecogId);
                        setFhirApiResponse(result);
                        let fhir = document.getElementById('fhir');
                        e.preventDefault();
                        setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                      }}
                    >
                      <ValueWithLabel label="ECOG Status">{regimen.ecogStatus}</ValueWithLabel>
                    </Link>
                    {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
                  </Box>
                  <ValueWithLabel label="ECOG Date">{regimen.ecogDate}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="m">
                  <ValueWithLabel label="Treatment Start Date">{regimen.start.split(' ')[0]}</ValueWithLabel>
                  <ValueWithLabel label="Treatment End Date">{regimen.end.split(' ')[0]}</ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </SpaceBetween>
            <Table
              variant="borderless"
              header={<Header variant="h2">Latest Treatment</Header>}
              empty="No medication administrations"
              wrapLines={true}
              items={regimen.lastTreatment}
              columnDefinitions={medicationAdministrationSummaryColumns}
            />
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            <br />
            <Header variant="h2">Cycle Information</Header>
            <Box padding={'m'} float="left">
              <ColumnLayout columns={6} variant="text-grid">
                <ValueWithLabel size="large" label="Total Cycles">
                  {regimen.cycleCount}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Active Cycles">
                  {regimen.activeCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Completed Cycles">
                  {regimen.completedCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Unknown Cycles">
                  {regimen.unknownCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Revoked Cycles">
                  {regimen.revokedCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="On-hold Cycles">
                  {regimen.onHoldCycles}
                </ValueWithLabel>
              </ColumnLayout>
            </Box>
            <Tabs
              tabs={regimen.cycles.map((cycle: PathwayCycle, index: number) => ({
                label: `${cycle.name}`,
                id: `${index}`,
                content: <CycleInfo key={index} cycle={cycle} />
              }))}
            />
            {regimen.futureCycles.length ? (
              <Box>
                <Header variant="h2">Future Cycle Information</Header>
                <br />
                <Tabs
                  tabs={regimen.futureCycles.map((cycle: PathwayCycle, index: number) => ({
                    label: `${cycle.name}`,
                    id: `${index}`,
                    content: <CycleInfo key={index} cycle={cycle} />
                  }))}
                />
              </Box>
            ) : null}
          </SpaceBetween>
        </Box>
      </>
    );
  };

  const SimpleRegimenDetails = ({ regimen }: { regimen: PathwayRegimen }) => {
    const medicationAdministrationSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationAdministration) => item.name,
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationAdministration) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationAdministration) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationAdministration) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      },
      {
        header: 'Date',
        cell: (item: PathwayMedicationAdministration) => item.start?.split(' ')[0],
        id: 'date'
      },
      {
        header: 'Start',
        cell: (item: PathwayMedicationAdministration) => (item.start?.split(' ')[1] ? item.start.split(' ')[1] : 'N/A'),
        id: 'start'
      },
      {
        header: 'End',
        cell: (item: PathwayMedicationAdministration) => (item.end?.split(' ')[1] ? item.end.split(' ')[1] : 'N/A'),
        id: 'end'
      }
    ];

    return (
      <>
        <Box padding={{ left: 'm', right: 'm' }}>
          <SpaceBetween size="m" direction="vertical">
            <SpaceBetween direction="vertical" size="m">
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween direction="vertical" size="xs">
                  <TextContent>
                    <p>
                      {'Intent of Treatment: '}
                      <strong>{regimen.intent}</strong>
                    </p>
                  </TextContent>
                  <TextContent>
                    <p>
                      {'Regimen: '}
                      <strong>{regimen.name}</strong>
                    </p>
                  </TextContent>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="xs">
                  <TextContent>
                    <p>
                      {regimen.lastTreatment?.[regimen.lastTreatment.length - 1] ? (
                        <strong>
                          <Link onClick={() => setLastTreatmentVisible(true)}>Last treatment: </Link>
                        </strong>
                      ) : (
                        'Last treatment: '
                      )}
                      {regimen.lastTreatment?.[regimen.lastTreatment.length - 1] ? (
                        <strong>
                          {regimen.lastTreatment?.[regimen.lastTreatment.length - 1]?.start.split(' ')[0]}
                        </strong>
                      ) : (
                        <strong>N/A</strong>
                      )}
                    </p>
                  </TextContent>
                  <TextContent>
                    <p>
                      {'Administered at: '}
                      <strong>
                        {regimen.lastTreatment?.[regimen.lastTreatment.length - 1]?.administeredAt || 'N/A'}
                      </strong>
                    </p>
                  </TextContent>{' '}
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="xs">
                  <TextContent>
                    <p>
                      {regimen.cycles?.[0] ? (
                        <strong>
                          <Link
                            onClick={(e) => {
                              setCurrentCycleVisible(true);
                            }}
                          >
                            Current treatment cycle:{' '}
                          </Link>
                        </strong>
                      ) : (
                        'Current treatment cycle: '
                      )}

                      {regimen.cycles?.[0] ? (
                        <>
                          Start date: <strong>{regimen.cycles?.[0].start.split(' ')[0]}</strong>, duration:{' '}
                          <strong>{regimen.cycles?.[0].duration} days</strong>
                        </>
                      ) : (
                        <strong>N/A</strong>
                      )}
                    </p>
                  </TextContent>
                  <TextContent>
                    <p>
                      {regimen.futureCycles?.[0] ? (
                        <strong>
                          <Link onClick={() => setFutureCycleVisible(true)}>Upcoming treatment cycle: </Link>
                        </strong>
                      ) : (
                        'Upcoming treatment cycle: '
                      )}
                      {regimen.futureCycles?.[0] ? (
                        <>
                          Start date: {regimen.futureCycles?.[0].start.split(' ')[0]}, duration:{' '}
                          {regimen.futureCycles?.[0].duration} days
                        </>
                      ) : (
                        <strong>N/A</strong>
                      )}
                    </p>
                  </TextContent>
                </SpaceBetween>
              </ColumnLayout>
            </SpaceBetween>
          </SpaceBetween>
        </Box>
        <Modal
          onDismiss={() => setLastTreatmentVisible(false)}
          visible={lastTreatmentVisible}
          size="max"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => setLastTreatmentVisible(false)} variant="primary">
                  Back
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Last Treatment"
        >
          <Table
            key={regimen.start}
            variant="borderless"
            empty="No medication administrations"
            wrapLines={true}
            items={regimen.lastTreatment}
            columnDefinitions={medicationAdministrationSummaryColumns}
          />
        </Modal>
        {regimen.cycles?.[0] ? (
          <Modal
            onDismiss={() => setCurrentCycleVisible(false)}
            visible={currentCycleVisible}
            size="max"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={(e) => {
                      setCurrentCycleVisible(false);
                    }}
                    variant="primary"
                  >
                    Back
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Current Cycle"
          >
            <SimpleCycleInfo key={regimen.start} cycle={regimen.cycles?.[0]} />
          </Modal>
        ) : null}
        {regimen.futureCycles?.[0] ? (
          <Modal
            onDismiss={() => setFutureCycleVisible(false)}
            visible={futureCycleVisible}
            size="max"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={() => setFutureCycleVisible(false)} variant="primary">
                    Back
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header="Future Cycle"
          >
            <SimpleCycleInfo key={regimen.start} cycle={regimen.futureCycles?.[0]} />
          </Modal>
        ) : null}
      </>
    );
  };

  const ClassicView = ({}) => {
    return (
      <div className="product-page-content-grid">
        <aside aria-label="Side bar" className="product-page-aside">
          <div className="product-page-aside-sticky">
            <SpaceBetween size="xl">
              <div className="on-this-page--side">
                <OnThisPageNavigation
                  displayDiagnosis={diagnoses.some((diagnosis) => diagnosis.diagnosis)}
                  displayRegimen={diagnoses.some((diagnosis) => diagnosis.regimens?.length > 0)}
                  displayStage={diagnoses.some((diagnosis) => diagnosis.diagnosis.stages?.length > 0)}
                  setViewSelection={setViewSelection}
                  viewSelection={viewSelection}
                />
              </div>
            </SpaceBetween>
          </div>
        </aside>
        <main className="product-page-content">
          <PatientInfo patient={patient} linkedNhis={linkedNhis} />
          <Tabs
            tabs={
              diagnoses.map((diagnosis: PathwayDiagnosisInfo, index: number) => ({
                label: `${diagnosis.diagnosis.diagnosis}`,
                id: `${index}`,
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <div id="diagnosis-details">
                      <DiagnosisDetails key={index} diagnosis={diagnosis.diagnosis} />
                    </div>
                    {diagnosis.diagnosis.stages?.length ? (
                      <div id="stage-details">
                        <StageDetails key={index} diagnosis={diagnosis.diagnosis} />
                      </div>
                    ) : null}
                    <div id="regimen-summary">
                      <Container header={<Header variant="h2">Regimen Summary</Header>}>
                        <Tabs
                          tabs={diagnosis.regimens.map((regimen, regimenIndex) => ({
                            label: `${regimen.name}`,
                            id: `${regimenIndex}`,
                            content: <RegimenDetails key={regimenIndex} regimen={regimen} />
                          }))}
                          activeTabId={regimenActiveTabId}
                          onChange={(e: any) => setRegimenActiveTabId(e.detail.activeTabId)}
                        />
                      </Container>
                    </div>
                  </SpaceBetween>
                )
              })) ?? []
            }
            activeTabId={diagnosisActiveTabId}
            onChange={(e) => setDiagnosisActiveTabId(e.detail.activeTabId)}
          />
          <div id="fhir">
            {fhirApiResponse.statusCode ? (
              <Container>
                <Results
                  apiResponse={fhirApiResponse}
                  setApiResponse={setFhirApiResponse}
                  button={
                    <Button
                      onClick={async (e) => {
                        let top = document.getElementById('top');
                        e.preventDefault();
                        await setTimeout(() => {
                          top?.scrollIntoView({ behavior: 'smooth' });
                        }, 100);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Container>
            ) : null}
          </div>
        </main>
      </div>
    );
  };

  const SimpleView = ({}) => {
    return (
      <div className="product-page-content-grid">
        <aside aria-label="Side bar" className="product-page-aside">
          <div className="product-page-aside-sticky">
            <SpaceBetween size="xl">
              <div className="on-this-page--side">
                <OnThisPageNavigation
                  displayDiagnosis={diagnoses.some((diagnosis) => diagnosis.diagnosis)}
                  displayRegimen={diagnoses.some((diagnosis) => diagnosis.regimens?.length > 0)}
                  displayStage={diagnoses.some((diagnosis) => diagnosis.diagnosis.stages?.length > 0)}
                  setViewSelection={setViewSelection}
                  viewSelection={viewSelection}
                />
              </div>
            </SpaceBetween>
          </div>
        </aside>
        <main className="product-page-content">
          <SimplePatientInfo patient={patient} linkedNhis={linkedNhis} />
          <Tabs
            tabs={
              diagnoses.map((diagnosis: PathwayDiagnosisInfo, index: number) => ({
                label: `Diagnosis ${diagnoses.length - index}`,
                id: `${index}`,
                content: (
                  <SpaceBetween direction="vertical" size="m">
                    <div id="diagnosis-details">
                      <SimpleDiagnosisDetails key={index} diagnosis={diagnosis.diagnosis} />
                    </div>
                    {diagnosis.diagnosis.stages?.length ? (
                      <div id="stage-details">
                        <SimpleStageDetails key={index} diagnosis={diagnosis.diagnosis} />
                      </div>
                    ) : null}
                    <div id="regimen-summary">
                      <Container>
                        <Tabs
                          activeTabId="sact"
                          tabs={[
                            {
                              label: 'MDM',
                              id: 'mdm',
                              content: '',
                              disabled: true
                            },
                            {
                              label: 'SACT',
                              id: 'sact',
                              content: (
                                <Tabs
                                  tabs={diagnosis.regimens.map((regimen, regimenIndex) => ({
                                    label: `Regimen ${diagnosis.regimens.length - regimenIndex}`,
                                    id: `${regimenIndex}`,
                                    content: <SimpleRegimenDetails key={regimenIndex} regimen={regimen} />
                                  }))}
                                  activeTabId={regimenActiveTabId}
                                  onChange={(e: any) => setRegimenActiveTabId(e.detail.activeTabId)}
                                ></Tabs>
                              )
                            },
                            {
                              label: 'Radiation',
                              id: 'radiation',
                              content: '',
                              disabled: true
                            },
                            {
                              label: 'Surgery',
                              id: 'surgery',
                              content: '',
                              disabled: true
                            }
                          ]}
                        />
                      </Container>
                    </div>
                  </SpaceBetween>
                )
              })) ?? []
            }
            activeTabId={diagnosisActiveTabId}
            onChange={(e) => setDiagnosisActiveTabId(e.detail.activeTabId)}
          />
          <div id="fhir">
            {fhirApiResponse.statusCode ? (
              <Container>
                <Results
                  apiResponse={fhirApiResponse}
                  setApiResponse={setFhirApiResponse}
                  button={
                    <Button
                      onClick={async (e) => {
                        let top = document.getElementById('top');
                        e.preventDefault();
                        await setTimeout(() => {
                          top?.scrollIntoView({ behavior: 'smooth' });
                        }, 100);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Container>
            ) : null}
          </div>
        </main>
      </div>
    );
  };

  function exploreResults() {
    return (
      <SpaceBetween direction="vertical" size="xxxs">
        <div id="top">
          <CustomBreadCrumb
            items={[{ text: `Patient Cancer Journey`, href: '/explore' }]}
            header={
              <Header
                actions={
                  <Button
                    onClick={() => {
                      setApiResponse(appSyncDefaultApiResponse);
                    }}
                  >
                    Back
                  </Button>
                }
                counter={`(${patient.nhi})`}
              >
                Patient Cancer Journey
              </Header>
            }
          />
        </div>
        {viewSelection.value === 'classic' ? <ClassicView /> : <SimpleView />}
      </SpaceBetween>
    );
  }

  function results() {
    return exploreResults();
  }

  return results();
};
