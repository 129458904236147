/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import React, { useEffect, useState } from 'react';
import { AppLayout } from '@cloudscape-design/components';
import '@cloudscape-design/global-styles/index.css';
import { Navigation } from './components/Navigation';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Landing } from './components/landing/Landing';
import { Create } from './components/create/Create';
import { Delete } from './components/delete/Delete';
import { Update } from './components/update/Update';
import { Read } from './components/read/Read';
import { Search } from './components/search/Search';
import { Explore } from './components/explore/Explore';
import AppSyncBackend from './common/backend/AppSyncBackend';
import FhirBackend from './common/backend/FhirBackend';
import { Callback } from './components/callback/Callback';
import { MetadataContext } from './stores/MetadataContext';
import axios from 'axios';
import ResourceMetadata from './stores/ResourceMetadata';
import FWoABackend from './common/backend/FWoABackend';
import { useHistory, useLocation } from 'react-router-dom';
import TopNavigation from './components/TopNavigation';
import { jwtDecode } from 'jwt-decode';

const Content = (
  fhirBackend: FhirBackend,
  appSyncBackend: AppSyncBackend,
  accessToken: string,
  setAccessToken: Function
) => {
  return (
    <Switch>
      <Route path="/create" render={() => <Create fhirBackend={fhirBackend} />} />
      <Route path="/read" render={(props) => <Read fhirBackend={fhirBackend} />} />
      <Route path="/search" render={(props) => <Search fhirBackend={fhirBackend} />} />
      <Route path="/update" render={(props) => <Update fhirBackend={fhirBackend} routeComponentProps={props} />} />
      <Route path="/delete" render={(props) => <Delete fhirBackend={fhirBackend} />} />
      <Route path="/callback" render={() => <Callback setAccessToken={setAccessToken} />} />
      <Route path="/explore" render={() => <Explore appSyncBackend={appSyncBackend} fhirBackend={fhirBackend} />} />
      <Route path="/" render={() => <Landing isLoggedIn={accessToken !== ''} identity="FHIRUser" />} />
    </Switch>
  );
};

interface AccessTokenClaims {
  [key: string]: any;
  sub: string;
  email: string;
  exp: number;
  preferred_username: string;
}

const ChildApp: React.FC = () => {
  const [navigationOpen, setNavigationOpen] = React.useState(true);
  const [claims, setClaims] = useState<AccessTokenClaims | null>(null);
  const history = useHistory();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState(() => {
    const history = useHistory();

    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      return storedToken;
    }

    if (!storedToken && !['/', '/callback'].includes(history.location.pathname)) {
      history.push('/');
    }

    return '';
  });

  useEffect(() => {
    if (accessToken) {
      const decodedToken: AccessTokenClaims = jwtDecode(accessToken);
      setClaims(decodedToken);
      const currentTime = new Date().getTime() / 1000;
      if (decodedToken.exp < currentTime) {
        console.log('Token has expired, log the user out');
        localStorage.removeItem('accessToken');
        setAccessToken('');
        setClaims(null);
        history.push('/');
      }
    }
  }, [accessToken, location]);

  const fhirBackend = new FWoABackend(accessToken);
  const appSyncBackend = new AppSyncBackend(accessToken);

  const [metadata, setMetadata] = React.useState<ResourceMetadata[]>([]);

  const fhirServerUrl = process.env.REACT_APP_FHIR_SERVER_URL;

  const mapCapstatementToResources = (capStatement: any): ResourceMetadata[] => {
    return capStatement.rest[0].resource.map((resource: any) => {
      return {
        type: resource.type,
        interaction: resource.interaction.map((interaction: { code: string }) => {
          return interaction.code;
        }),
        searchParam: resource.searchParam,
        searchInclude: resource.searchInclude,
        searchRevInclude: resource.searchRevInclude
      };
    });
  };

  useEffect(() => {
    const fetchCapStatement = async () => {
      const result = await axios.get(`${fhirServerUrl}/metadata`);
      setMetadata(mapCapstatementToResources(result.data));
    };

    fetchCapStatement();
  }, []);

  // using pathname to invoke useEffect method because tracking history doesn't work
  const [pathname, setPathname] = useState('/');

  history.listen((location) => {
    setPathname(location.pathname);
  });

  return (
    <MetadataContext.Provider value={{ metadata, accessToken }}>
      <TopNavigation isLoggedIn={accessToken !== ''} identity={claims?.email || claims?.preferred_username || 'N/A'} />
      <AppLayout
        content={Content(fhirBackend, appSyncBackend, accessToken, setAccessToken)}
        navigation={<Navigation />}
        navigationHide={!accessToken ? true : false}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        toolsHide={true}
      />
    </MetadataContext.Provider>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <ChildApp />
    </Router>
  );
};

export default App;
