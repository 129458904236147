import { StatusIndicator } from '@cloudscape-design/components';

const Status = ({ children }: any) => {
  switch (children) {
    case 'completed':
      return (
        <StatusIndicator type="info" colorOverride="green">
          Completed
        </StatusIndicator>
      );
    case 'cancelled':
      return <StatusIndicator type="warning">Cancelled</StatusIndicator>;
    case 'stopped':
      return <StatusIndicator type="warning">Stopped</StatusIndicator>;
    case 'entered-in-error':
      return <StatusIndicator type="warning">Entered in error</StatusIndicator>;
    case 'unknown':
      return <StatusIndicator type="stopped">Unknown</StatusIndicator>;
    case 'active':
      return (
        <StatusIndicator type="in-progress" colorOverride="green">
          Active
        </StatusIndicator>
      );
    case 'in-progress':
      return (
        <StatusIndicator type="in-progress" colorOverride="blue">
          In Progress
        </StatusIndicator>
      );
    default:
      return children;
  }
};

export default Status;
