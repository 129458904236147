/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { getAccessTokenState, isAccessTokenValid } from '../../common/utils/AccessTokenUtil';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { storeAccessTokenState } from '../../common/utils/AccessTokenUtil';

export const Callback: React.FC<{ setAccessToken: Function }> = ({ setAccessToken }) => {
  const history = useHistory();
  useEffect(() => {
    const parsedHash = queryString.parse(history.location.hash);
    const { state, access_token } = parsedHash;
    if (!access_token) {
      const { REACT_APP_AUTH_URL, REACT_APP_CLIENT_ID } = process.env;
      const authUrl = new URL(`${REACT_APP_AUTH_URL}` || '');
      authUrl.searchParams.append('response_type', 'token');
      const stateValue = uuidv4();
      storeAccessTokenState(stateValue);
      authUrl.searchParams.append('state', stateValue);
      authUrl.searchParams.append('nonce', stateValue);
      authUrl.searchParams.append('client_id', REACT_APP_CLIENT_ID || '');
      authUrl.searchParams.append('scope', 'profile email openid system/*.*');
      authUrl.searchParams.append('redirect_uri', encodeURI(`${window.location.href}`));
      window.location.href = authUrl.href;
    } else {
      history.push('/');
    }

    // Context: https://auth0.com/docs/protocols/state-parameters
    if (!isAccessTokenValid(state as string)) {
      setErrorMessage(
        `Returned state for access token did not match sent state for access token. 
        Sent State: ${getAccessTokenState()}, Returned State: ${state}`
      );
    }
    setAccessToken(access_token);
    localStorage.setItem('accessToken', access_token as string);
    history.push('/');
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  return <div>${errorMessage}</div>;
};
// export default withRouter(Callback);
