import TopNavigation from '@cloudscape-design/components/top-navigation';
import { clearAccessTokenState } from '../common/utils/AccessTokenUtil';
import { useHistory } from 'react-router-dom';

export interface TopNavigationProps {
  isLoggedIn: boolean;
  identity: string;
}

export default (props: TopNavigationProps) => {
  const history = useHistory();

  return props.isLoggedIn ? (
    <TopNavigation
      identity={{
        href: '/',
        title: 'PCDS Demo',
        logo: { src: '/logo512.png', alt: 'Service' }
      }}
      utilities={[
        {
          type: 'menu-dropdown',
          text: props.identity,
          iconName: 'user-profile',
          onItemClick: () => {
            clearAccessTokenState();
            window.location.reload();
          },
          items: [{ id: 'signout', text: 'Sign out' }]
        }
      ]}
    />
  ) : (
    <></>
  );
};
