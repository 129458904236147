export function validateNHI(nhi: string): string {
  // Check if the length is 7
  if (nhi.length !== 7) {
    return 'NHI must be exactly 7 characters';
  }

  // Check if the first three characters are alphabetic
  if (!/^[a-zA-Z]{3}$/.test(nhi.slice(0, 3))) {
    return 'NHI must start with three characters';
  }

  // Check if the next two characters are numeric
  if (!/^\d{2}$/.test(nhi.slice(3, 5))) {
    return 'NHI must have two numeric characters following the first three alphabetic characters';
  }

  // Check if the last two characters are alphabetic or numeric
  if (!/^[a-zA-Z0-9]{2}$/.test(nhi.slice(5, 7))) {
    return 'NHI must end with two alphabetic or numeric characters';
  }

  return '';
}
