/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import { useHistory } from 'react-router-dom';

import React from 'react';
import { SideNavigation, SideNavigationProps } from '@cloudscape-design/components';
const { REACT_APP_FHIR_SERVER_URL } = process.env;

export const navItems: SideNavigationProps.Section[] = [
  {
    type: 'section',
    text: 'PCDS',
    items: [{ type: 'link', text: 'Patient Cancer Pathway', href: '/explore' }]
  },
  {
    type: 'section',
    text: 'Resources',
    items: [
      {
        type: 'link',
        text: 'Solution Architecture',
        external: true,
        href: 'https://mohits.atlassian.net/wiki/spaces/CanShare/pages/3782705258'
      },
      {
        type: 'link',
        text: 'Solution Use Cases',
        external: true,
        href: 'https://mohits.atlassian.net/wiki/spaces/CanShare/pages/3796140037/Test+Use+Cases'
      },
      {
        type: 'link',
        text: 'ACT-NOW Specification',
        external: true,
        href: 'https://build.fhir.org/ig/davidhay25/actnow/resource-graph.html'
      },
      {
        type: 'link',
        text: 'CanShare Bundle Visualizer',
        external: true,
        href: 'http://test.canshare.co.nz/validateBundle.html'
      },
      {
        type: 'link',
        text: 'Capability Statement',
        href: `${REACT_APP_FHIR_SERVER_URL}/metadata`,
        external: true
      }
    ]
  },
  {
    type: 'section',
    defaultExpanded: false,
    text: 'CanSOT FHIR Tools',
    items: [
      { type: 'link', text: 'Create', href: '/create' },
      { type: 'link', text: 'Update', href: '/update' },
      { type: 'link', text: 'Read', href: '/read' },
      { type: 'link', text: 'Search', href: '/search' },
      { type: 'link', text: 'Delete', href: '/delete' }
    ]
  }
];

export const Navigation: React.FC<{}> = () => {
  const history = useHistory();
  function onFollowHandler(ev: any) {
    if (!ev.detail.href.startsWith('http')) {
      ev.preventDefault();
      history.push(ev.detail.href);
    }
  }

  return <SideNavigation items={navItems} activeHref={history.location.pathname} onFollow={onFollowHandler} />;
};
