/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import { Box, Button, Container, ContentLayout, Grid, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { storeAccessTokenState } from '../../common/utils/AccessTokenUtil';

export const Landing: React.FC<{
  isLoggedIn: boolean;
  identity: string;
}> = ({ isLoggedIn }) => {
  // Check user logged in state
  if (!isLoggedIn) {
    const { REACT_APP_AUTH_URL, REACT_APP_CLIENT_ID } = process.env;
    const authUrl = new URL(`${REACT_APP_AUTH_URL}` || '');
    authUrl.searchParams.append('response_type', 'token');
    const stateValue = uuidv4();
    storeAccessTokenState(stateValue);
    authUrl.searchParams.append('state', stateValue);
    authUrl.searchParams.append('nonce', stateValue);
    authUrl.searchParams.append('client_id', REACT_APP_CLIENT_ID || '');
    authUrl.searchParams.append('scope', 'profile email openid system/*.*');
    authUrl.searchParams.append('redirect_uri', encodeURI(`${window.location.href}callback`));
    window.location.href = authUrl.href;
  }

  return (
    <>
      {isLoggedIn && (
        <ContentLayout
          headerBackgroundStyle={(mode) => `center center/cover url("/image.png")`}
          header={
            <Box padding={'xxxl'}>
              <Grid gridDefinition={[{ colspan: { default: 12, s: 8 } }]}>
                <Container>
                  <Box padding="s">
                    <Box fontSize="display-l" fontWeight="bold" variant="h1" padding="n">
                      PCDS Technical Proof of Concept
                    </Box>
                    <Box fontSize="display-l" fontWeight="light">
                      Holistic view of the Patient Cancer Pathway
                    </Box>
                    {`The patient experience for CanShare is supported by the Cancer Pathway, which is realized through Patient-Centred Data Services (PCDS).
                    The Cancer Pathway is the gateway between the patient and all individuals and groups involved in patient care, such as Iwi, Hapū and Whānau, Community Groups, NGOs etc. It maintains a single immutable record of the patient's current state and status about their cancer journey and a complete history of all tracked events on that journey. It ensures compliance with the requirements of the patient's care plan across all parties.
                    Patient engagement and safety will be tracked and managed through the CPIR Patient Pathway, ensuring patients receive the care and entitlements they need at each stage of their cancer journey.`
                      .split('\n')
                      .map((paragraph, index) => (
                        <Box key={index} variant="p" color="text-body-secondary" margin={{ top: 'xs', bottom: 'l' }}>
                          {paragraph}
                        </Box>
                      ))}
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="primary" href="/explore">
                        Explore Patient Cancer Pathway
                      </Button>
                      <Button
                        href="https://mohits.atlassian.net/wiki/spaces/CanShare/pages/3782705258"
                        iconAlign="right"
                        iconName="external"
                        target="_blank"
                      >
                        Solution Architecture
                      </Button>
                    </SpaceBetween>
                  </Box>
                </Container>
              </Grid>
            </Box>
          }
        />
      )}
    </>
  );
};
