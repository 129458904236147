// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Box, AnchorNavigation, Select, SpaceBetween } from '@cloudscape-design/components';
function OnThisPageNavigation({
  displayDiagnosis,
  displayStage,
  displayRegimen,
  viewSelection,
  setViewSelection
}: {
  displayDiagnosis: boolean;
  displayStage: boolean;
  displayRegimen: boolean;
  viewSelection: any;
  setViewSelection: any;
}) {
  const anchors = [];

  if (displayDiagnosis && viewSelection.value == 'classic') {
    anchors.push({
      text: 'Diagnosis Details',
      href: '#diagnosis-details',
      level: 1
    });
  }

  if (displayStage && viewSelection.value == 'classic') {
    anchors.push({
      text: 'Stage Details',
      href: '#stage-details',
      level: 1
    });
  }

  if (displayRegimen && viewSelection.value == 'classic') {
    anchors.push({
      text: 'Regimen Summary',
      href: '#regimen-summary',
      level: 1
    });
  }

  const anchorNavigation = (
    <AnchorNavigation
      anchors={[
        {
          text: 'Patient Summary',
          href: '#top',
          level: 1
        },
        ...anchors
      ]}
      ariaLabelledby="navigation-header"
      onFollow={async (e) => {
        e.preventDefault();
        let anchor = document.getElementById(e.detail.href.split('#')[1]);
        await setTimeout(() => {
          anchor?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }}
    />
  );

  return (
    <div className="on-this-page--side" data-testid="on-this-page">
      <Box variant="h2" margin={{ bottom: 'xxs' }}>
        <span id="navigation-header">Pathway Index</span>
      </Box>
      <SpaceBetween direction="vertical" size="m">
        {anchorNavigation}
        <Select
          selectedOption={viewSelection}
          onChange={({ detail }) => {
            setViewSelection(detail.selectedOption);
          }}
          options={[
            { label: 'Simple View', value: 'simple' },
            { label: 'Classic View', value: 'classic' }
          ].sort((a, b) => {
            // Move the selected option to the front
            if (a.value === viewSelection.value) return -1;
            if (b.value === viewSelection.value) return 1;
            return 0;
          })}
        />
      </SpaceBetween>
    </div>
  );
}

export { OnThisPageNavigation };
