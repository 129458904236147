import { Box } from '@cloudscape-design/components';

export const ValueWithLabel = ({ label, children, size }: any) => (
  <>
    <Box variant="awsui-key-label">{label}</Box>
    {size === 'large' ? <Box variant="awsui-value-large">{children}</Box> : <div>{children}</div>}
  </>
);

export default ValueWithLabel;
